import logo from './logo.svg';
import './App.css';
import react from "react";
import request from "./helpe";
import  $ from 'jquery'

import axios from "axios";

class App extends react.Component {
    constructor() {
        super();
        this.state = {
            playstate: false,
        };
    }

    componentDidMount() {
        // setTimeout(_=>{
        //
        //     var _palyer =document.getElementById("vd");
        //     function initAutoPlay () {
        //         console.log("1")
        //         _palyer.muted = false
        //         _palyer.autoPlay=true
        //         _palyer.play();
        //         document.removeEventListener('touchstart', initAutoPlay, false);
        //     }
        //     function playAudio () {
        //         console.log("2")
        //         _palyer.muted = false
        //         _palyer.autoPlay=true
        //         _palyer.play();
        //     }
        //     document.addEventListener('touchstart', initAutoPlay, false); // 解决部分浏览器禁止自动播放问题
        //     document.addEventListener('WeixinJSBridgeReady', playAudio, false); // 解决微信浏览器
        //
        //     // request({
        //     //     url:'https://naddr.oss-cn-beijing.aliyuncs.com/dly.mp3',
        //     //     method:'get',
        //     //     responseType:'blob',
        //     // }).then((res)=>{
        //     //     var fileURL=window.URL.createObjectURL(new Blob([res.data]))
        //     //     var fileLink= document.createElement('a')
        //     //
        //     //     fileLink.href=fileURL;
        //     //     fileLink.setAttribute('download','my.mp3');
        //     //     document.body.append(fileLink);
        //     //     fileLink.click();
        //     // })
        //
        //
        //     // let audio = new Audio("https://naddr.oss-cn-beijing.aliyuncs.com/dly.mp3")
        //     // let audio = document.getElementById("vd")
        //     //
        //     // audio.muted = false
        //     // audio.autoPlay=true
        //     // audio.play();
        //     // console.log(audio);
        //
        // },1000*3)
    };

    // play(){
    //     //let audio = new Audio("https://naddr.oss-cn-beijing.aliyuncs.com/dly.mp3")
    //     let audio = document.getElementById("vd")
    //     audio.muted = false
    //     audio.autoPlay=true
    //     audio.play();
    //     console.log(audio);
    //     this.setState({
    //         playstate:true
    //     })
    //
    // };


    sendMail() {
        // eslint-disable-next-line no-unused-expressions
        const options = {};
        const url = 'https://api.mysubmail.com/mail/send';
        const data = {
            appid: "16454",
            to: "zhangwenliang@eyemove.net",
            from: "service@bloc.eyemove.net",
            subject: "Test",
            text: "能不能收到呢",
            signature: "7e7466b7b14dc67cb7d0380fd13abfed"
        }
        options.method = 'post'
        options.mode = 'no-cors'
        options.body = JSON.stringify(data)
        options.headers = {
            'Content-Type': 'application/json'
        }
        // fetch(url,options,{credentials:'include'})
        //
        //     .then((res) => {
        //         console.log(res)
        //     })
        //     .catch(err=>({err}))

        $.ajax({
            url:"https://api.mysubmail.com/mail/send",
            type:'POST',
            data:{
                appid: "16454",
                to: "zhangwenliang@eyemove.net",
                from: "service@bloc.eyemove.net",
                subject: "Test",
                text: "能不能收到呢",
                signature: "7e7466b7b14dc67cb7d0380fd13abfed"
            },
            success:function (res){
                console.log("email===="+res)
            },
            error:function (err){
                console.log("email ===="+err)
            }
        })
        // eslint-disable-next-line no-unused-expressions
        // axios.post('https://api.mysubmail.com/mail/send', {
        //     appid: "16454",
        //     to: "zhangwenliang@eyemove.net",
        //     from: "service@bloc.eyemove.net",
        //     subject: "Test",
        //     text: "能不能收到呢",
        //     signature: "7e7466b7b14dc67cb7d0380fd13abfed"
        // }).then(function (res) {
        //     console.log(res)
        // }).catch(function (err) {
        //     console.log(err)
        // }), {
        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'multipart/form-data'
        //     }
        // }
    }


    render() {
        return (
            <div className="App" >
                {/*<div hidden={this.state.playstate} id="kq" onTouchStart={_=>this.play()}>开启</div>*/}
                <button className={"sendmail"} id={"send"} onClick={_=>this.sendMail()}>发送邮件</button>
                <header className="App-header" >
                    {/*<iframe id="iframe" src="https://modao.cc/app/uXvWgLEkr3bpjpoAfoB1Hz/embed/v2"  width="100%" height={window.innerHeight+'px'}*/}
                    {/*        frameBorder="0" >*/}
                    {/*    /!*<audio id="vd" ref="audioId" src={this.state.src} muted={false} autoPlay={true} controls={false}></audio>*!/*/}
                    {/*    <audio id="vd" src='https://naddr.oss-cn-beijing.aliyuncs.com/dly.mp3'  loop={true} muted autoPlay={true} ></audio>*/}
                    {/*</iframe>*/}
                    {/*//https://naddr.oss-cn-beijing.aliyuncs.com/dly.mp3*  http://eyemall.vip/eyemove/dly.mp3/}
                    {/*<embed src="https://nenghao.eyemove.net/dly.mp3" autostart="true" loop="true" hidden="ture"></embed>*/}
                    {/*<audio loop="loop" autoplay={false} muted={true} controls={false}>*/}
                    {/*    <source src="https://nenghao.eyemove.net/dly.mp3"/>*/}
                    {/*</audio>*/}


                </header>

            </div>

        );
    }


}

export default App;
